import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Layout from 'components/Layout';
import Wrapper from 'components/Wrapper';
import HomeFeed from 'components/HomeFeed';
import Sidebar from 'components/Sidebar';
import TopAd from 'components/TopAd';
import Content from 'components/Content';
import site from 'data/site';
import SEO from 'utils/SEO';

const Page = styled.div`
	display: grid;
	width: 100%;
	${theme.media.large} {
		grid-template-areas: 'main side' 'feed side';
		grid-template-columns: minmax(0, 1fr) auto;
	}
	${theme.media.notlarge} {
		grid-template-areas: 'main' 'side' 'feed';
		grid-template-columns: 100%;
	}
	grid-gap: ${theme.size(4)};
`;
const Main = styled.div`
	grid-area: main;
	background: ${theme.colors.background[0]};
	padding: ${theme.size(4)};
`;
const Side = styled.div`
	grid-area: side;
`;
const Feed = styled.div`
	grid-area: feed;
`;

export default props => {
	return (
		<Layout>
			<SEO title="About us" />
			<Wrapper>
				<TopAd />
				<Page>
					<Main>
						<Content>
							<h1>About {site.text.logo.title}</h1>
							<p>Welcome! We're excited that you want to learn more about us.</p>
							<p>
								Here at {site.text.logo.title}, we offer you top financial advices and latest news from
								the business and financial sectors, to keep you up-to-date about what is happening in
								the industry right now. Want to save money, pay off debts or spend money by buying a
								house, car or investment? You are in the right place!
							</p>
							<p />
							<h2>Learn more about {site.text.logo.title}</h2>
							{site.nav.map((item, i) => (
								<p key={i}>
									<a href={item.slug}>{item.title}</a>
								</p>
							))}
						</Content>
					</Main>
					<Feed>
						<HomeFeed />
					</Feed>
					<Side>
						<Sidebar />
					</Side>
				</Page>
			</Wrapper>
		</Layout>
	);
};
